import { Controller } from "stimulus";
// import { toggle } from "el-transition";

export default class extends Controller {
  static targets = ["amount", "customAmount", "design", "preview"];
  static classes = ["unchecked", "checked"];

  connect() {
    this.amountTargets[0].parentElement.classList.add(
      ...this.checkedClass.split(" ")
    );

    this.designTargets[0].parentElement.classList.add(
      ...this.checkedClass.split(" ")
    );
  }

  toggleAmount(e) {
    this.amountTargets.forEach((amount) => {
      amount.parentElement.classList.remove(...this.checkedClass.split(" "));
      amount.parentElement.classList.add(...this.uncheckedClass.split(" "));
    });

    e.target.parentElement.classList.remove(...this.uncheckedClass.split(" "));
    e.target.parentElement.classList.add(...this.checkedClass.split(" "));

    this.customAmountTarget.classList.remove(...this.checkedClass.split(" "));
    this.customAmountTarget.classList.add(...this.uncheckedClass.split(" "));
    this.customAmountTarget.value = "";
  }

  toggleDesign(e) {
    this.designTargets.forEach((amount) => {
      amount.parentElement.classList.remove(...this.checkedClass.split(" "));
      amount.parentElement.classList.add(...this.uncheckedClass.split(" "));
    });

    e.target.parentElement.classList.remove(...this.uncheckedClass.split(" "));
    e.target.parentElement.classList.add(...this.checkedClass.split(" "));

    this.previewTarget.src = e.target.dataset.previewImage;
  }

  focusCustomAmount(e) {
    this.amountTargets.forEach((amount) => {
      amount.parentElement.classList.remove(...this.checkedClass.split(" "));
      amount.parentElement.classList.add(...this.uncheckedClass.split(" "));
    });
  }

  blurCustomAmount(e) {
    if (e.target.value !== "") {
      e.target.classList.remove(...this.uncheckedClass.split(" "));
      e.target.classList.add(...this.checkedClass.split(" "));
    }
  }
}
