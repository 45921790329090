import { Controller } from "stimulus";
import { toggle } from "el-transition";

export default class extends Controller {
  static targets = ["toggleable"];

  hideNow() {
    this.toggleableTargets.forEach((t) => t.classList.add("hidden"));
  }

  toggle() {
    this.toggleableTargets.forEach((t) => {
      // don’t toggle if already toggled (eg. if behaviour is set to once)
      if (t.dataset.toggled) {
        return;
      }

      if (t.dataset.toggleClass) {
        t.dataset.toggleClass.split(" ").forEach((c) => t.classList.toggle(c));
      } else {
        if (t.dataset.transitionEnter !== undefined) {
          toggle(t);
        } else {
          t.classList.toggle("hidden");
        }
      }

      // if the toggle should only happen once, save that we have toggled
      // successfully so we can return early on the next toggle
      if (t.dataset.toggleBehaviour === "once") {
        t.dataset.toggled = true;
      }
    });
  }
}
