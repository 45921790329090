import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    if (this.hasUrlValue) {
      if (this.urlValue.slice(0, 1) === "/") {
        window.Turbo.visit(this.urlValue)
      } else {
        window.location = this.urlValue
      }
    } else {
      console.error("location-href: no urlValue provided")
    }
  }
}
