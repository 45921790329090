import { Controller } from "stimulus"
import _debounce from "lodash.debounce"

// needs to be connected to a <form>
export default class extends Controller {
  connect () {
    this.submit = _debounce(this.submitNow, 200, { trailing: true }).bind(this)
  }

  submitNow () {
    this.element.requestSubmit()
  }
}
