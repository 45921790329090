import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["focusable"];

  focus() {
    // either focus an explicit element or the first input
    const $focusable = this.hasFocusableTarget ? this.focusableTarget : this.element.querySelector('input:not([type="hidden"]), textarea');

    // select contents to easily overwrite
    $focusable?.select();
  }

  // bind key down events as an action to this method
  // and it will focus the input when pressing /, except
  // if the user is typing in an input field
  onKey({ key, target }) {
    if (target.tagName !== "INPUT" && key === "/") {
      this.focus();
    }
  }
}
