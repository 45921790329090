import { Controller } from "stimulus";

export default class extends Controller {
  // could add more methods: removeSelf, removeAll (use removable targets)

  static values = {
    delay: Number
  }

  connect () {
    if (this.hasDelayValue) {
      setTimeout(() => this.removeRoot(), this.delayValue)
    }
  }

  removeRoot () {
    this.element.remove()
  }
}
