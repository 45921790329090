import { Controller } from "stimulus";
import { isValidIBAN, friendlyFormatIBAN } from "ibantools";

export default class extends Controller {
  static targets = ["iban"];

  connect() {
    if (this.hasIbanTarget) {
      this.ibanTarget.innerText = friendlyFormatIBAN(this.ibanTarget.innerText);
    }
  }

  blur({ currentTarget }) {
    if (isValidIBAN(currentTarget.value.trim())) {
      currentTarget.value = friendlyFormatIBAN(currentTarget.value);
    }
  }

  // validate(iban)

  // append() {
  //   const el = document.importNode(this.templateTarget.content, true);
  //   this.containerTarget.appendChild(el);
  // }

  // appendBeforeLast() {
  //   const el = document.importNode(this.templateTarget.content, true);
  //   this.containerTarget.insertBefore(
  //     el,
  //     this.containerTarget.lastElementChild
  //   );
  // }
}
