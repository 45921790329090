import { Controller } from "stimulus";
import { toggle } from "el-transition";

export default class extends Controller {
  static targets = ["overlay", "backdrop", "content"];

  open() {
    this.overlayTarget.classList.remove("hidden");
    toggle(this.backdropTarget);
    toggle(this.contentTarget);

    const e = new CustomEvent("overlay:open");
    this.element.dispatchEvent(e);
  }

  close() {
    toggle(this.backdropTarget);
    toggle(this.contentTarget);
    setTimeout(() => this.overlayTarget.classList.add("hidden"), 110);
  }

  keydown({ key }) {
    if (key === "Escape") {
      this.close();
    }
  }
}
