import { Controller } from "stimulus"
import { leave, toggle } from "el-transition"

export default class extends Controller {
  static targets = ["trigger", "menu"]

  connect () {
    this.isOpen = false
  }

  toggle (e) {
    e.preventDefault()
    this.isOpen = !this.isOpen
    toggle(this.menuTarget)
    this.dispatchEvents()
  }

  // clickOutside is bound to document using stimulus global binding
  // and closes the context menu when a click outside of it is detected
  clickOutside (e) {
    if (this.element.contains(e.target)) {
      return
    }

    this.close()
  }

  keyDown ({ key, target }) {
    if (key === "Escape" && target === this.triggerTarget) {
      this.close()
    }
  }

  close () {
    if (this.isOpen === false) {
      return
    }

    this.isOpen = false
    leave(this.menuTarget)
    this.dispatchEvents()
  }

  closeNow () {
    if (this.isOpen === false) {
      return
    }

    this.isOpen = false
    this.menuTarget.classList.add("hidden")
    this.dispatchEvents()
  }

  dispatchEvents () {
    if (this.isOpen === true) {
      this.element.dispatchEvent(new CustomEvent("context-menu:open"))
    } else {
      this.element.dispatchEvent(new CustomEvent("context-menu:close"))
    }
  }
}
