import { Controller } from "stimulus";
import { leave } from "el-transition";

export default class extends Controller {
  connect() {
    setTimeout(() => this.remove(), 3000);
  }

  remove() {
    leave(this.element).then(() => this.element.remove());
  }
}
