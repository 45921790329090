import { Controller } from 'stimulus';

export default class extends Controller {
  connect () {
    this.element.querySelectorAll('input').forEach(input => {
      if (input.dataset.originalValue === undefined) {
        input.dataset.originalValue = input.value
      }
    })
  }

  reset () {
    this.element.querySelectorAll('input').forEach(input => {
      console.log(input.dataset.originalValue)
      // if (input.dataset.originalValue.length > 0) {
        input.value = input.dataset.originalValue
      // }
    })
  }
}
