import { Controller } from "stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = [ "trigger", "cart" ]

  connect () {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          leave(this.cartTarget)
        } else {
          enter(this.cartTarget)
        }
      })
    })

    this.observer.observe(this.triggerTarget)
  }
}
