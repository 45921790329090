import { Controller } from 'stimulus';

export default class extends Controller {
  connect () {
    setTimeout(() => this.element.requestSubmit(), 1000)
  }

  // onSubmit () {
  //   // disable submit button to prevent duplicate submits
  //   this.element.querySelectorAll('button[type=submit]').forEach (submit => submit.disabled = true)
  // }

  // onSubmitEnd () {
  //   this.element.querySelectorAll('button[type=submit]').forEach(submit => submit.disabled = false)
  // }

  // submit () {
  //   this.element.requestSubmit()
  // }

  // disableValidation () {
  //   this.element.noValidate = true
  // }
}
