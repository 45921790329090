import * as Turbo from "@hotwired/turbo";
import "form-request-submit-polyfill"; // come on Safari get it together
import { register as registerSwipe } from "swiper/element/bundle";
registerSwipe();

import { Application as Stimulus } from "stimulus";
const application = Stimulus.start();

application.register("form", require("../controllers/form_controller").default);
application.register("form-reset", require("../controllers/form_reset_controller").default);
application.register("form-throttle", require("../controllers/form_throttle_controller").default);
application.register("search", require("../controllers/search").default);
application.register("replace", require("../controllers/replace_controller").default);
application.register("toggle", require("../controllers/toggle_controller").default);
application.register("overlay", require("../controllers/overlay_controller").default);
application.register("context-menu", require("../controllers/context_menu_controller").default);
application.register("floating-cart", require("../controllers/floating-cart").default);
application.register("remove", require("../controllers/remove_controller").default);
application.register("location-href", require("../controllers/location-href").default);
application.register("focus", require("../controllers/focus_controller").default);
// application.register("required-selection", require("../controllers/required_selection_controller").default)
application.register("scroll-fixer", require("../controllers/scroll-fixer").default);
application.register("form/auto-submit", require("../controllers/form-auto-submit").default);
application.register("charcount", require("../controllers/charcount").default);
application.register("theme-color", require("../controllers/theme-color").default);
application.register("image-zoom", require("../controllers/image-zoom").default);

application.register("voucher-picker", require("../controllers/voucher-picker").default);

application.register("flash", require("../controllers/flash_controller").default);

application.register("catalogue-filter", require("../controllers/catalogue-filter").default);

application.register("iban", require("../controllers/iban").default);
application.register("scroll", require("../controllers/scroll").default);
