import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    scroll: Number
  }

  connect () {
    this.element.scrollLeft = this.scrollValue
  }

  scroll () {
    this.scrollLeft = this.element.scrollLeft
  }

  cache () {
    this.scrollValue = this.scrollLeft
  }
}
