import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["category", "tag", "scroll-trigger"];

  connect() {
    this.activeClasses = this.element.dataset.activeClass.split(" ");

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // don’t run this on lg and up
        if (window.innerWidth >= 1024) {
          return;
        }

        if (entry.isIntersecting) {
          enter(this.element);
          console.log("enter");
        } else {
          leave(this.element);
          console.log("leave");
        }
      });
    });

    this.observer.observe(document.querySelector("#nav-scroll-trigger"));
  }

  scrollIntoView() {
    if (this.element.dataset.scrollIntoView === "true") {
      document.querySelector("turbo-frame#catalogue_products").scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  clickCategory({ currentTarget }) {
    this.categoryTargets.forEach((el) => {
      el.classList.remove(...this.activeClasses);
    });

    currentTarget.classList.add(...this.activeClasses);

    const url = new URL(location);
    const target = new URL(currentTarget.href);
    url.search = target.searchParams.toString();
    history.replaceState({}, "", url);

    this.scrollIntoView();
  }

  clickTag({ currentTarget }) {
    if (currentTarget.classList.contains(...this.activeClasses)) {
      currentTarget.classList.remove(...this.activeClasses);
    } else {
      currentTarget.classList.add(...this.activeClasses);
    }

    const url = new URL(location);
    const target = new URL(currentTarget.href);
    url.search = target.searchParams.toString();
    history.replaceState({}, "", url);

    this.scrollIntoView();
  }
}
