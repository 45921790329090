import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    this.metaTag = document.querySelector("meta[name=theme-color]")
  }

  darken () {
    this.metaTag.setAttribute("content", this.metaTag.dataset.dark)
  }

  lighten () {
    this.metaTag.setAttribute("content", this.metaTag.dataset.light)
  }

  // // static values = {
  // //   scroll: Number
  // // }

  // connect () {
  //   this.element.scrollLeft = this.scrollValue
  // }

  // scroll () {
  //   this.scrollLeft = this.element.scrollLeft
  // }

  // cache () {
  //   this.scrollValue = this.scrollLeft
  // }
}
