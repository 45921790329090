import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  connect() {
    this.overlays = {};
  }

  open({ currentTarget }) {
    if (this.overlays[currentTarget] !== undefined) {
      enter(this.overlays[currentTarget]);
    } else {
      const o = document.createElement("div");
      o.dataset.turboCache = false;
      o.classList.add(
        "js-image-zoom",
        "fixed",
        "inset-0",
        "p-4",
        "lg:p-8",
        "bg-white/90",
        "z-50",
        "flex",
        "justify-center",
        "items-center",
        "cursor-zoom-out",
        "hidden",
      );

      // Transition.fadeIn
      const transition = {
        transitionEnter: "transition ease-out duration-150",
        transitionEnterStart: "opacity-0",
        transitionEnterEnd: "opacity-100",
        transitionLeave: "transition ease-in duration-100",
        transitionLeaveStart: "opacity-100",
        transitionLeaveEnd: "opacity-0",
      };

      Object.entries(transition).map(([k, v]) => {
        o.dataset[k] = v;
      });

      o.addEventListener("click", ({ currentTarget }) => leave(currentTarget));

      const img = document.createElement("img");
      img.classList.add(
        "object-center",
        "object-contain",
        "w-full",
        "h-full",
        "max-h-[1800px]",
        "z-40",
      );
      img.setAttribute(
        "src",
        currentTarget.dataset?.src || currentTarget.getAttribute("src"),
      );

      // const spinner = document.createElement("div");
      // spinner.classList.add(
      //   "absolute",
      //   "top-1/2",
      //   "left-1/2",
      //   "-translate-x-1/2",
      //   "-translate-y-1/2",
      //   "w-8",
      //   "h-8",
      //   "bg-red-500",
      //   "z-30",
      //   "animate-spin",
      // );

      o.appendChild(img);
      // o.appendChild(spinner);
      document.body.appendChild(o);

      enter(o);
      this.overlays[currentTarget] = o;
    }
  }

  keydown({ key }) {
    if (key === "Escape") {
      document
        .querySelectorAll(".js-image-zoom:not(.hidden)")
        .forEach((z) => leave(z));
    }
  }
}
