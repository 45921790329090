import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "result", "loading", "submit"];

  connect() {
    this._selectedIdx = undefined;
  }

  get selectedIdx() {
    return this._selectedIdx;
  }

  set selectedIdx(idx) {
    this._selectedIdx = idx;
    this.resultTargets.forEach((result) => {
      result.classList.remove("bg-slate-50");
    });

    return idx;
  }

  submitStart(e) {
    this.submitTarget.classList.add("hidden");
    this.loadingTarget.classList.remove("hidden");
  }

  submitEnd(e) {
    this.loadingTarget.classList.add("hidden");
    this.submitTarget.classList.remove("hidden");
  }

  keyDown(e) {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        if (this.selectedIdx === undefined) {
          this.selectedIdx = 0;
        }

        if (this.selectedIdx - 1 < 0) {
          this.selectedIdx = this.resultTargets.length - 1;
        } else {
          this.selectedIdx--;
        }

        this.resultTargets[this.selectedIdx]?.classList.add("bg-slate-50");
        break;

      case "ArrowDown":
        e.preventDefault();

        if (this.selectedIdx === undefined) {
          this.selectedIdx = 0;
        } else {
          if (this.selectedIdx + 1 >= this.resultTargets.length) {
            this.selectedIdx = 0;
          } else {
            this.selectedIdx++;
          }
        }

        this.resultTargets[this.selectedIdx]?.classList.add("bg-slate-50");
        break;

      case "Enter":
        if (this.selectedIdx === undefined) break;

        const url = this.resultTargets[this.selectedIdx]?.getAttribute("href");
        window.Turbo.visit(url);
        break;

      default:
        this.selectedIdx = undefined;
        break;
    }
  }

  mouseOver(e) {
    this.selectedIdx = this.resultTargets.indexOf(e.target);
    this.resultTargets[this.selectedIdx]?.classList.add("bg-slate-50");
  }
}
