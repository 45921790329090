import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "replaceable" ]

  replaceFromSelect ({ target }) {
    const $opt = target.options[target.selectedIndex]
    this.replace($opt.dataset.value)
  }

  replace (value) {
    this.replaceableTargets.forEach( t => t.innerHTML = value)
  }
}
