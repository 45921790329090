import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "count"]

  connect () {
    this.update()
  }

  update () {
    let count = this.inputTarget.value.trim().length
    if (count <= 0) {
      this.clear()
      return
    }

    if (this.inputTarget.getAttribute("maxlength") !== null) {
      count += `/${this.inputTarget.getAttribute("maxlength")}`
    }

    this.countTarget.innerText = count
  }

  clear () {
    this.countTarget.innerText = ""
  }
}
